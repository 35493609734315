var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_vm._m(0),_c('div',{staticClass:"innerImg"},[_c('img',{attrs:{"src":require("@/assets/img/index/1.jpg"),"alt":""}}),_c('router-link',{staticClass:"link link1",attrs:{"to":"/studyAbroadApply"}}),_c('router-link',{staticClass:"link link2",attrs:{"to":"/course"}})],1),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner innerImg",staticStyle:{"margin-top":"50px"}},[_c('img',{attrs:{"src":require("@/assets/img/index/banner.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"innerImg"},[_c('img',{attrs:{"src":require("@/assets/img/index/2.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"innerImg"},[_c('img',{attrs:{"src":require("@/assets/img/index/3.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"innerImg"},[_c('img',{attrs:{"src":require("@/assets/img/index/4.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"innerImg"},[_c('img',{attrs:{"src":require("@/assets/img/index/5.jpg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"innerImg"},[_c('img',{attrs:{"src":require("@/assets/img/index/6.jpg"),"alt":""}})])
}]

export { render, staticRenderFns }