<template>
  <div class="index">
    <div class="banner innerImg" style="margin-top: 50px;">
      <img src="@/assets/img/index/banner.jpg" alt="" />
    </div>
    <div class="innerImg">
      <img src="@/assets/img/index/1.jpg" alt="" />
		<router-link class="link link1" to="/studyAbroadApply"></router-link>
		<router-link class="link link2" to="/course"></router-link>
    </div>
    <div class="innerImg">
      <img src="@/assets/img/index/2.jpg" alt="" />
    </div>
    <div class="innerImg">
      <img src="@/assets/img/index/3.jpg" alt="" />
    </div>
    <div class="innerImg">
      <img src="@/assets/img/index/4.jpg" alt="" />
    </div>
    <div class="innerImg">
      <img src="@/assets/img/index/5.jpg" alt="" />
    </div>
    <div class="innerImg">
      <img src="@/assets/img/index/6.jpg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Index",
  data() {
    return {
      offers: [
        {
          name: "王同学",
          comlogo: require("@/assets/comlogo/bilibili.png"),
          money: "¥41w",
          lesson: "北美SDE算法速成班",
        },
        {
          name: "唐同学",
          comlogo: require("@/assets/comlogo/wangyi.png"),
          money: "¥30w",
          lesson: "北美SDE算法速成班",
        },
        {
          name: "杨同学",
          comlogo: require("@/assets/comlogo/uber.png"),
          money: "$35w",
          lesson: "北美SDE求职班",
        },
        {
          name: "周同学",
          comlogo: require("@/assets/comlogo/ama.png"),
          money: "$23w",
          lesson: "北美SDE算法速成班",
        },
        {
          name: "李同学",
          comlogo: require("@/assets/comlogo/ali.png"),
          money: "$45w",
          lesson: "北美SDE算法速成班",
        },
        {
          name: "高同学",
          comlogo: require("@/assets/comlogo/netelix.png"),
          money: "$70w",
          lesson: "北美SDE求职班",
        },
        {
          name: "刘同学",
          comlogo: require("@/assets/comlogo/fb.png"),
          money: "$19w",
          lesson: "北美SDE求职班",
        },
        {
          name: "贾同学",
          comlogo: require("@/assets/comlogo/wangyi.png"),
          money: "¥27w",
          lesson: "北美SDE算法速成班",
        },
        {
          name: "陈同学",
          comlogo: require("@/assets/comlogo/mic.png"),
          money: "$16w",
          lesson: "北美SDE求职班",
        },
        {
          name: "郭同学",
          comlogo: require("@/assets/comlogo/jingdong.png"),
          money: "¥20w",
          lesson: "北美SDE算法速成班",
        },
      ],
    };
  },
};
</script>
<style scoped lang="scss">
.innerImg {
	position: relative;
	font-size: 0;
	img {
		width: 100%;
	}
	.link{
		display: block;
		position: absolute;
		width: 50%;
		height: 200px;
		bottom: 30px;
	}
	.link1{
		left: 0;
	}
	.link2{
		right: 0;
	}
}

.offer .title {
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  line-height: 18px;
  margin-bottom: 12px;
}

.offer .offer-head {
  border-left: 3px solid #f9f9fb;
  border-right: 3px solid #f9f9fb;
}
.offer .offer-sum ul {
  display: flex;
}
.offer .offer-sum ul li {
  width: 33.33%;

  border-right: 3px solid #f9f9fb;
  box-sizing: border-box;
}

.offer .offer-sum ul li:last-child {
  border: none;
}

.offer .offer-sum ul li h2 {
  font-weight: 500;
  color: #4eb2de;
  line-height: 30px;
  text-align: center;
}

.offer .offer-sum ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #898989;
  line-height: 21px;
  text-align: center;
}

.offer .offer-table {
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.offer .offer-table ul li {
  padding: 17px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  height: 70px;
}
.offer .offer-table table {
  width: 100%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.offer .offer-table table tbody {
  height: 200px;
  overflow-y: scroll;
  display: block;
}

.offer .offer-table table thead,
.offer .offer-table table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  /**表格列的宽度由表格宽度决定，不由内容决定*/
  text-align: center;
}

.offer .offer-table table th {
  font-size: 16px;
  background: #fff;
  padding: 15px;
  font-weight: normal;
}

.offer .offer-table table td {
  font-size: 16px;
  background: #f8f8fb;
  padding: 10px;
  font-weight: normal;
  text-align: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.offer .offer-table .cell {
  display: inline-block;
  width: 25%;
  text-align: center;
  vertical-align: top;
}

.offer .offer-table .student img {
  width: 40px;
  border-radius: 100%;
  margin-right: 5px;
  vertical-align: middle;
}

.offer .offer-table .company img {
  width: 100px;
  height: auto;
  /* height: 40px; */
  vertical-align: top;
  display: inline-block;
}

.offer .offer-table .money {
  color: #e02020;
  font-size: 16px;
  vertical-align: top;
}
.seamless-warp {
  height: 700px;
  overflow: hidden;
}
ul li {
  list-style: none;
}
ul {
  margin: 0;
  padding-inline-start: 0px;
}
.item {
  padding: 0;
  margin: 0;
}
.offer-table-head.clearfix {
  display: flex;
  justify-content: center;
  p {
    width: 25%;
    text-align: center;
  }
}
</style>